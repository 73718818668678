import React, { FC } from 'react';

interface LayoutProps {
  children?: React.ReactNode;
  laptopWidth?: boolean;
}

const Layout: FC<LayoutProps> = ({ children, laptopWidth = true }): JSX.Element => {
  return (
    <div className="page">
      <div className="page-container px-large px-tablet-none">
        <div className="page-content mt-huge2x mx-none mx-tablet-gigantic2x mx-laptop-none">
          <div className="row">
            <div className={`col-12 ${laptopWidth ? 'col-laptop-8' : ''}`}>{children}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Layout;
