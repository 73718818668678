import { i18n } from '@wefox/i18n';
import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import { navigateToUrl } from 'single-spa';

const BackButton: FC = (): JSX.Element => {
  const { policyId } = useParams();

  const backButtonOnClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    event.preventDefault();
    // TODO: Replace contract path for something more dinamic
    navigateToUrl(`/contracts/${policyId}`);
  };

  return (
    <div className="d-flex">
      <div>
        <wefox-link
          class="d-block pb-large"
          aria-label="back-button"
          role="button"
          color="link"
          name="cancel"
          onClick={backButtonOnClick}
        >
          <wefox-icon icon="arrow-left" icon-set="icons" class="pr-3" />
          <span className="d-inline-block pl-small text-caption">{`${i18n.getTranslation('GLOBAL_back')}`}</span>
        </wefox-link>
      </div>
    </div>
  );
};

export default BackButton;
